<!-- dictionary-edit -->
<template>
  <el-dialog
    class="dictionary-edit"
    :visible.sync="open"
    :title="title"
    width="560px"
    :close-on-click-modal="false"
    @open="handleDialogOpen"
    @closed="handleDialogClosed"
  >
    <el-form
      class="dictionary-edit-form"
      ref="form"
      v-loading="loading"
      :model="model"
      :rules="rules"
      size="small"
      label-width="85px"
      @submit.native.prevent
    >
      <el-form-item label="字典值:" prop="name">
        <el-input v-model.trim="model.name" placeholder="请输入" clearable />
      </el-form-item>

      <el-form-item label="备注:">
        <el-input
          v-model.trim="model.remark"
          type="textarea"
          :rows="3"
          placeholder="请输入"
          clearable
        />
      </el-form-item>
    </el-form>

    <template v-slot:footer>
      <div class="dictionary-edit-operate">
        <el-button size="small" @click="handleOperateCancelClick"
          >取消
        </el-button>

        <el-button
          type="primary"
          size="small"
          :loading="saving"
          @click="handleOperateOkClick"
          >确定
        </el-button>
      </div>
    </template>
  </el-dialog>
</template>

<script>
  import {
    getDictionary,
    addDictionary,
    updateDictionary,
  } from '../../../../api/sys/dictionary';

  export default {
    name: 'dictionary-edit',
    props: {
      // 是否开启对话框
      value: {
        type: Boolean,
        default: false,
      },
      // 字典 id
      id: {
        type: String,
        default: '',
      },
      // 字典类型
      type: {
        type: Number,
        required: true,
      },
    },
    data() {
      return {
        // 当前编辑的字典对象
        dictionary: null,
        model: null,
        rules: {
          name: [{ required: true, message: '请填写', trigger: 'change' }],
        },
        loading: false,
        saving: false,
      };
    },
    computed: {
      open: {
        get() {
          return this.value;
        },
        set(val) {
          this.$emit('input', val);
        },
      },
      // 是否是编辑
      isEdit() {
        return !!this.id;
      },
      // 对话框标题
      title() {
        return `${this.isEdit ? '编辑' : '新增'}字典`;
      },
    },
    created() {
      this.initModel();
    },
    methods: {
      // 初始化模型
      initModel(data = {}) {
        const { name = '', remark = '' } = data || {};

        this.model = {
          name,
          remark,
        };
      },
      // 校验模型
      async validateModel() {
        try {
          return await this.$refs.form.validate();
        } catch (e) {
          return false;
        }
      },
      // 重置模型
      resetModel() {
        this.initModel();
        this.$refs.form.resetFields();
      },
      // 保存模型
      async saveModel() {
        const params = {
          ...this.model,
          id: this.id,
          type: this.type,
        };
        const method = this.isEdit ? updateDictionary : addDictionary;
        const res = await method(params);

        return !!res;
      },
      // 获取字典对象详情
      async getDictionary() {
        const res = await getDictionary(this.id);

        if (!res) return false;

        this.dictionary = res.data;

        return true;
      },
      // 对话框打开
      async handleDialogOpen() {
        if (!this.isEdit) return;

        this.loading = true;

        const success = await this.getDictionary();

        this.loading = false;

        if (!success) return;

        this.initModel(this.dictionary);
      },
      // 对话框关闭完成
      handleDialogClosed() {
        this.dictionary = null;

        this.resetModel();
      },
      // 对话框取消按钮单击
      handleOperateCancelClick() {
        this.open = false;
      },
      // 对话框确定按钮单击
      async handleOperateOkClick() {
        const isValid = await this.validateModel();

        if (!isValid) return;

        this.saving = true;

        const success = await this.saveModel();

        this.saving = false;

        if (!success) return;

        this.open = false;
        this.$emit('on-ok');
      },
    },
  };
</script>

<style scoped lang="scss">
  .dictionary-edit {
    .dictionary-edit-form {
      .el-input {
        width: 100%;
      }
    }
  }
</style>
